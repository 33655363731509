* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.homePageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100vh;
}

.formWrapper {
  background: #282a36;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.mainLabel {
  margin-bottom: 20px;
  margin-top: 0;
}

.homePageLogo {
  height: 80px;
  margin-bottom: 30px;
}

.inputBox {
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-bottom: 14px;
  background: #eee;
  font-size: 16px;
  font-weight: bold;
}

.btn {
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.joinBtn {
  background: #4aed88;
  width: 100px;
  margin-left: auto;
}
.leaveBtn {
  background: rgb(193, 6, 6);
  width: 100px;
  margin-left: auto;
}
.copyBtn {
  background: rgb(135, 135, 135);
}

.joinBtn:hover {
  background: #2b824c;
}
.leaveBtn:hover {
  background: rgb(118, 3, 3);
}

.copyBtn:hover {
  background: rgb(88, 87, 87);
}

.createInfo {
  margin: 0 auto;
  margin-top: 20px;
}

.createNewBtn {
  color: #4aed88;
  text-decoration: none;
  border-bottom: 1px solid #4aed88;
  transition: all 0.3s ease-in-out;
}

.createNewBtn:hover,
footer a:hover {
  color: #368654;
  border-color: #368654;
}

.mainWrap {
  display: grid;
  grid-template-columns: 230px 1fr 350px;
}

.aside {
  background: #1c1e29;
  padding: 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.asideInner {
  flex: 1;
}

.logo {
  border-bottom: 1px solid #424242;
  padding-bottom: 10px;
}

.logoImage {
  height: 60px;
}

.leaveBtn {
  width: 100%;
  margin-top: 20px;
}
.realtimeEditor {
  min-height: calc(100vh - 285px);
  font-size: 20px;
  line-height: 1.6;
  padding-top: 20px;
  width: 100%;
  max-height: calc(100vh - 271px);
}
.huihui {
  max-width: calc(100vw - 580px);
}
.terminal {
  height: 215px;
  width: 100%;
}
.inputBox {
  border: 1px solid #9ca3af;
  border-radius: none;
  background: #1c1e29;
  width: 50%;
}
.outputBox {
  border: 1px solid #9ca3af;
  border-radius: 1px;
  width: 50%;
  overflow: auto;
}

select {
  background: #1c1e29;
}

.topbar {
  height: 56px;
}
